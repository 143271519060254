<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="300"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-info :labelCol="10" :valueCol="12" title="剩余库存" :value="goods.quantity" />
        <e-info :labelCol="10" :valueCol="12" title="单价" :value="goods.stockPrice" />
        <e-info :labelCol="10" :valueCol="12" title="单位" :value="goods.unit" />
        <s-select :labelCol="10" :valueCol="12" title="供应商" v-model:value="supplierId" :options="suppliers" />
        <s-input-number title="入库数量" v-model:value="count" />
        <s-switch :labelCol="10" :valueCol="12" title="修改价格" v-model:value="updateGoodsStockPrice" help="修改价格必须大于0" />
        <s-input-number v-if="updateGoodsStockPrice" title="入库价格" v-model:value="stockPrice" />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import { GoodsInClass } from '@/apis/goods'

const api = new GoodsInClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const updateGoodsStockPrice = ref(false)
    const stockPrice = ref(0)

    const title = ref('')
    const goods = ref({})
    const unit = ref('')
    const count = ref(0)
    const suppliers = ref()
    const supplierId = ref()

    const onClose = () => {
      count.value = 0
      emit('ok')
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      goods,
      count,
      unit,
      onClose,
      updateGoodsStockPrice,
      stockPrice,
      suppliers,
      supplierId
    }
  },

  methods: {
    async onGetAllSuppliers () {
      const list =
        this.$store.state.data.ALL_SUPPLIER.length > 0
          ? this.$store.state.data.ALL_SUPPLIER
          : await this.$store.dispatch('GetAllSuppliers')

      const nlist = []

      list.map((e) => {
        nlist.push({
          label: e.name,
          value: e.id
        })
      })
      this.suppliers = nlist
    },
    open (formData) {
      this.goods = formData
      this.title = '商品“' + formData.title + '”入库'

      this.onGetAllSuppliers()
      this.visible = true
    },

    onReset () {
      this.modelRef.title = ''
      this.modelRef.icon = ''
      this.modelRef.thumbnail = ''
      this.modelRef.banner = ''
    },

    onSubmitFun () {
      this.loading = true

      if (this.count <= 0) {
        this.$message.error('库存必须大于0')
        return
      }
      if (this.updateGoodsStockPrice && this.stockPrice <= 0) {
        this.$message.error('修改价格必须大于0')
        return
      }

      api
        .create({
          goodsId: this.goods.id,
          quantity: this.count,
          updateGoodsStockPrice: this.updateGoodsStockPrice,
          stockPrice: this.stockPrice,
          supplierId: this.supplierId
        })
        .then(() => {
          this.$message.success('入库成功')
          this.onClose()
        })
    }
  }
})
</script>

<style lang="less" scoped></style>
