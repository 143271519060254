<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="600"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          title="商品标题"
          :validate="validateInfos.title"
          v-model:value="modelRef.title"
        />
        <e-cascader
          title="商品类型"
          :options="cateOptions"
          :validate="validateInfos.goodsCategoryId"
          v-model:value="parentIds"
          @ok="changeCategory"
        />

        <e-input-upload title="商品图片" v-model:value="modelRef.pic" />

        <e-input title="品牌" v-model:value="modelRef.brand" :valueCol="8" />

        <e-input
          title="规格"
          v-model:value="modelRef.specification"
          :valueCol="8"
        />

        <e-input title="单位" v-model:value="modelRef.unit" :valueCol="8" />

        <a-row style="margin-left: -5px">
          <a-col :span="10">
            <e-input-number
              title="进货价"
              v-model:value="modelRef.stockPrice"
            />
          </a-col>
        </a-row>

        <e-input
          title="转换单位"
          v-model:value="modelRef.transferUnit"
          :valueCol="8"
        />
        <a-row style="margin-left: -5px">
          <a-col :span="10">
            <e-input-number
              title="转换倍数"
              v-model:value="modelRef.transferNum"
            />
          </a-col>
        </a-row>
      </a-form>
    </a-spin>

    <Resource ref="resource" @ok="emitChooseResource" />
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import {} from '@ant-design/icons-vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { GoodsClass } from '@/apis/goods'
import Resource from '@/components/Resource'
import store from '@/store'
import { dataToCascader, getParentIdArray } from '@/utils'

const useForm = Form.useForm
const api = new GoodsClass()
export default defineComponent({
  components: {
    Resource
  },
  mixins: [form],
  emits: ['ok'],
  setup () {
    const loading = ref(false)
    const visible = ref(false)
    const isEdit = ref(false)
    const title = ref('')
    const cateOptions = ref([])
    const parentIds = ref([])

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      parentIds.value = []
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      isEdit,
      loading,
      modelRef,
      validateInfos,
      unitOptions: store.state.app.unit,
      cateOptions,
      parentIds,
      resetFields,
      validate,
      onClose
    }
  },

  methods: {
    open (formData) {
      if (formData) {
        console.log(formData)
        this.modelRef.title = formData.title
        this.modelRef.subTitle = formData.subTitle
        this.modelRef.pic = formData.pic
        this.modelRef.stockPrice = formData.stockPrice
        this.modelRef.sellingPrice = formData.sellingPrice
        this.modelRef.promotionPrice = formData.promotionPrice
        this.modelRef.quantity = formData.quantity
        this.modelRef.unit = formData.unit
        this.modelRef.brand = formData.brand
        this.modelRef.specification = formData.specification
        this.modelRef.goodsCategoryId = formData.goodsCategoryId
        this.modelRef.transferUnit = formData.transferUnit
        this.modelRef.transferNum = formData.transferNum
        this.modelRef.id = formData.id

        this.title = '编辑：' + formData.title
        this.isEdit = true
      } else {
        this.modelRef = reactive(api.modelRef)

        this.title = '添加商品'
        this.isEdit = false
      }

      this.getCategorys()
      this.visible = true
    },

    changeCategory (e) {
      this.modelRef.goodsCategoryId = e[e.length - 1]
    },

    async getCategorys () {
      const list =
        this.$store.state.data.ALL_GOODSCATEGORYS.length > 0
          ? this.$store.state.data.ALL_GOODSCATEGORYS
          : await this.$store.dispatch('GetAllGoodsCategorys')

      this.cateOptions = dataToCascader(list, 0)

      if (this.isEdit) {
        const parentIds = getParentIdArray(
          toRaw(list),
          this.modelRef.goodsCategoryId
        )
        parentIds.sort((a, b) => a - b)
        console.log(parentIds)
        this.parentIds = parentIds.filter((x) => x > 0)
      }
    },

    emitChooseResource (e) {
      const image = e.images[0]
      const field = e.field
      this.modelRef[field] = image
    },

    onSubmitFun (e) {
      const data = toRaw(e)

      if (this.isEdit) {
        api.update(data).then(() => {
          this.loading = false
          this.$message.success('编辑成功')
          this.$emit('ok', data)
          this.onClose()
        })
      } else {
        api.create(data).then(() => {
          this.loading = false
          this.$message.success('添加成功')
          this.$emit('ok', data)
          this.onClose()
        })
      }
    }
  }
})
</script>

<style lang="less" scoped>
.label-title {
  position: relative;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  text-align: right;

  &:after {
    content: ":";
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}
</style>
